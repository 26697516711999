<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Reservas - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                
              
                <b-col md="12">
                  <label for="">Nro Reserva :</label> <strong>{{reservation.number}}</strong>
                </b-col>

                <b-col md="12">
                  <label for="">Nombres :</label> <strong>{{reservation.name}}</strong>
                </b-col>

                <b-col md="12">
                  <label for="">Email :</label> <strong>{{reservation.email}}</strong>
                </b-col>

                <b-col md="12">
                  <label for="">Teléfono :</label> <strong>{{reservation.phone}}</strong>
                </b-col>

                <b-col md="12">
                  <label for="">Pasajeros :</label> <strong> {{reservation.adults }} adulto(s) y {{reservation.kids }} niño(s)</strong>
                </b-col>

                <b-col md="12">
                  <label for="">Hora de llegada aproximada :</label> <strong>{{reservation.checkin_hour}}</strong>
                </b-col>

                <b-col md="12">
                  <label for="">Peticiones :</label> <strong>{{reservation.requests}}</strong>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="5%" class="text-center">#</th>
                          <th width="40%" class="text-center">Habitacion</th>
                          <th width="8%" class="text-center">Cantidad</th>
                          <th width="10%" class="text-center">F. Llegada</th>
                          <th width="10%" class="text-center">F. Salida</th>
                          <th width="5%" class="text-center">Noches</th>
                          <th width="8%" class="text-center">Precio x estadia</th>
                          <th width="8%" class="text-center">Total</th>
                          <th width="7%" class="text-center">Estado</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in reservation.reservation_detail" :key="it">
                        <tr>
                          <td class="text-center">{{it +1}}</td>
                          <td class="text-left">{{item.name}}</td>
                          <td class="text-center">{{item.quantity}}</td>
                          <td class="text-left">{{item.name_from}}</td>
                          <td class="text-left">{{item.name_to}}</td>
                          <td class="text-center">{{item.nights}}</td>
                          <td class="text-right">{{item.total_price}}</td>
                          <td class="text-right">{{item.final_price}}</td>
                          <td class="text-center">
                            <b-badge v-if="item.state == 1" variant="success">Reservado</b-badge>
                            <b-badge v-if="item.state == 0" variant="danger">No Reservado</b-badge>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>


                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Regresar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'

export default {
  name: "reservationEdit",
  props: ["id_reservation"],
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  
  data() {
    return {
      module:'reservation',
      isLoading: false,
      reservation: {
        id_reservation: "",
        id_guest: "",
        number: "",
        name: "",
        phone: "PE",
        email: "",
        subtotal: "",
        discount: "",
        total: "",
        data_payment: "",
        requests:"",
        checkin_hour:"",
        adults:"",
        kids:"",
        state: 1,
        reservation_detail:[]
      },

      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],

      validate: false,
    };
  },
  mounted() {
    this.Viewreservation();
  },
  methods: {
    Viewreservation,
    EditReservation,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//ver usuario
function Viewreservation() {
  let me = this;
  let id_reservation = je.decrypt(me.id_reservation);
  let url = me.url_base + "reservation/view/" + id_reservation;
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: 3,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.reservation.id_reservation = response.data.result.reservation.id_reservation;
        me.reservation.id_guest = response.data.result.reservation.id_guest;
        me.reservation.number = response.data.result.reservation.number;
        me.reservation.name = response.data.result.reservation.name;
        me.reservation.phone = response.data.result.reservation.phone;
        me.reservation.email = response.data.result.reservation.email;
        me.reservation.guest_email = response.data.result.reservation.guest_email;
        me.reservation.phone = response.data.result.reservation.phone;
        me.reservation.subtotal = response.data.result.reservation.subtotal;
        me.reservation.discount = response.data.result.reservation.discount;
        me.reservation.total = response.data.result.reservation.total;
        me.reservation.data_payment = response.data.result.reservation.data_payment;
        me.reservation.state = response.data.result.reservation.state;
        me.reservation.checkin_hour = response.data.result.reservation.checkin_hour;
        me.reservation.requests = response.data.result.reservation.requests;
        me.reservation.adults = response.data.result.reservation.adults;
        me.reservation.kids = response.data.result.reservation.kids;
        me.reservation.reservation_detail = response.data.result.reservation_detail;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditReservation(_this) {

  let me = _this;
  let url = me.url_base + "reservation/edit";
  let data = me.reservation;

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token, module: me.module, role: 3,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: 'Se ha modificado correctamente los datos del reservatione', timer: 3000,})
      } else if(response.data.status == 200) {
          Swal.fire({ icon: 'warning', text: 'El reservatione ingresado ya se encuentra registrado', timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {


  // let me = this;

  // if (!this.validate) {
  //   Swal.fire({
  //     title: "Esta seguro de modificar los datos del reservatione?",
  //     text: "",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, Estoy de acuerdo!",
  //   }).then((result) => {
  //     if (result.value) {
  //       this.EditReservation(me);
  //     }
  //   });

  // }

}
</script>
